import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  IconButton,
  TableCell,
  TableRow,
  TextField,
} from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useAppDispatch, useAppSelector } from 'app/store';
import { selectUserCompanies } from 'app/store/userCompaniesSlice';
import ProgressButton from 'app/shared-components/ui/ProgressButton';
import {
  getHighnotePaymentCard,
  assignPaymentCard,
  selectSpecificCardSlice,
  resetSpecificCardSlice,
  unassignPaymentCard,
} from 'app/pages/store/specificCardSlice';
import { isErrorLike } from 'util/serializeError';
import useDeepCompareEffect from 'use-deep-compare-effect';
import ResponsiveTable from 'app/shared-components/layout/table/ResponsiveTable';
import { Close } from '@mui/icons-material';
import { PaidolUserToHighnotePaymentCard } from 'API';
import { format } from 'date-fns';
import ProcoreProjectAutocomplete from 'app/shared-components/forms/procore-project/ProcoreProjectAutocomplete';
import ProcoreUserAutocomplete from 'app/shared-components/forms/procore-user/ProcoreUserAutocomplete';
import theme from 'app/theme';

/**
 * Form Validation Schema
 */
const schema = yup.object({
  isConstructionType: yup.bool(),
  isAgaveClient: yup.bool(),
  highnotePaymentCardId: yup.string().required(),
  email: yup.string().required('You must enter a email').email('You must enter a valid email'),
  project: yup
    .object()
    .when(['isConstructionType', 'isAgaveClient'], {
      is: (isConstructionType: boolean, isAgaveClient: boolean) =>
        isConstructionType === true && !isAgaveClient,
      then: yup.object().nullable().required('You must select a project'),
      otherwise: yup.object().notRequired(),
    })
    .when(['isConstructionType', 'isAgaveClient'], {
      is: (isConstructionType: boolean, isAgaveClient: boolean) => !(isConstructionType || isAgaveClient),
      then: yup.object().notRequired(),
    }),

  user: yup
    .object()
    .when(['isConstructionType', 'isAgaveClient'], {
      is: (isConstructionType: boolean, isAgaveClient: boolean) =>
        isConstructionType === true && !isAgaveClient,
      then: yup.object().nullable().required('You must select a user'),
      otherwise: yup.object().notRequired(),
    })
    .when(['isConstructionType', 'isAgaveClient'], {
      is: (isConstructionType: boolean, isAgaveClient: boolean) => !(isConstructionType || isAgaveClient),
      then: yup.object().notRequired(),
    }),
});

type FormValues = yup.InferType<typeof schema>;

interface AssignCardProps {
  selectedCardId?: string;
  onClose?: () => void;
  onSuccess?: (paymentCardId: string) => void;
}

function AssignCardForm({ selectedCardId, onClose, onSuccess }: AssignCardProps) {
  const dispatch = useAppDispatch();
  const { selectedPaidol, selectedPaidolId: selectedCompany } = useAppSelector(selectUserCompanies);
  const { paymentCardDetails: selectedCardDetails } = useAppSelector(selectSpecificCardSlice);
  const selectedCard = selectedCardDetails?.highnotePaymentCard;

  const isConstructionType = useMemo(
    () => selectedPaidol?.isConstructionType ?? false,
    [selectedPaidol?.isConstructionType]
  );

  const isAgaveClient = useMemo(
    () => selectedPaidol?.isAgaveClient ?? false,
    [selectedPaidol?.isAgaveClient]
  );

  const methods = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      email: '',
      isConstructionType,
      isAgaveClient,
      project: undefined,
      user: undefined,
      highnotePaymentCardId: '',
    },
    resolver: yupResolver(schema),
  });
  const { control, formState, getValues, setValue, setError } = methods;
  const { errors, isValid } = formState;
  const [isLoading, setIsLoading] = useState(false);
  const [removePending, setRemovePending] = useState<string | undefined>(undefined);

  const users = useMemo(
    () =>
      (selectedCard?.paidolUsers?.items || []).filter(
        (user) => user !== null
      ) as PaidolUserToHighnotePaymentCard[],
    [selectedCard?.paidolUsers?.items]
  );

  const canAssignNewUser = useMemo(() => users.length < 1, [users.length]);

  useEffect(() => {
    if (selectedCardId && selectedCompany) {
      dispatch(
        getHighnotePaymentCard({
          paymentCardId: selectedCardId,
          paidolId: selectedCompany,
          yearAndMonth: format(new Date(), 'yyyy-MM'),
        })
      );
    }

    return () => {
      dispatch(resetSpecificCardSlice());
    };
  }, [dispatch, selectedCardId, selectedCompany]);

  useDeepCompareEffect(() => {
    if (selectedCard) {
      setValue('highnotePaymentCardId', selectedCard.paymentCardId, {
        shouldDirty: false,
      });
    }
  }, [selectedCard, setValue]);

  const assignCard = useCallback(() => {
    const { email, user, highnotePaymentCardId } = getValues();
    setIsLoading(true);
    dispatch(
      assignPaymentCard({
        input: {
          email,
          employee_id: user?.id,
          paidol_id: selectedCompany,
        },
        highnotePaymentCardId,
      })
    ).then((res) => {
      setIsLoading(false);
      if (isErrorLike(res?.payload)) {
        setError('email', { type: 'manual', message: res?.payload?.message });
      } else {
        if (onSuccess) {
          onSuccess(highnotePaymentCardId);
        }

        if (onClose) {
          onClose();
        }
      }
    });
  }, [dispatch, getValues, onClose, onSuccess, selectedCompany, setError]);

  const removeUser = useCallback(
    (paidolUserToHighnotePaymentCardId: string) => {
      if (selectedCardId && selectedCompany) {
        setRemovePending(paidolUserToHighnotePaymentCardId);
        dispatch(unassignPaymentCard(paidolUserToHighnotePaymentCardId)).then(() => {
          dispatch(
            getHighnotePaymentCard({
              paymentCardId: selectedCardId,
              paidolId: selectedCompany,
              yearAndMonth: format(new Date(), 'yyyy-MM'),
            })
          ).then(() => {
            setRemovePending(undefined);
          });

          if (onSuccess) {
            onSuccess(selectedCardId);
          }
        });
      }
    },
    [dispatch, onSuccess, selectedCardId, selectedCompany]
  );

  return (
    <>
      <Box sx={{ p: 2 }}>
        <FormProvider {...methods}>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box sx={{ flexGrow: 1, mr: 2 }}>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="email"
                    error={!!errors.email}
                    helperText={errors?.email?.message}
                    label="Email"
                    id="email"
                    variant="outlined"
                    required
                    fullWidth
                    disabled={!canAssignNewUser}
                  />
                )}
              />
            </Box>
            <Box sx={{ flexGrow: 1, mr: 2 }}>
              <Controller
                name="highnotePaymentCardId"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="text"
                    error={!!errors.highnotePaymentCardId}
                    helperText={errors?.highnotePaymentCardId?.message}
                    label="Payment Card ID"
                    id="highnotePaymentCardId"
                    variant="outlined"
                    disabled={!!selectedCardId}
                    required
                    fullWidth
                  />
                )}
              />
            </Box>
          </Box>
          {isConstructionType && !isAgaveClient && (
            <Box sx={{ display: 'flex', flexDirection: 'row', mt: 2 }}>
              <Box sx={{ flexGrow: 1, mr: 2 }}>
                <ProcoreProjectAutocomplete />
              </Box>
              <Box sx={{ flexGrow: 1, mr: 2 }}>
                <ProcoreUserAutocomplete />
              </Box>
            </Box>
          )}
          <Box sx={{ mt: 4, display: 'flex', justifyContent: 'right' }}>
            <Button sx={{ mr: 2 }} onClick={onClose}>
              Cancel
            </Button>
            <ProgressButton
              loading={isLoading}
              disabled={!isValid || isLoading || !canAssignNewUser}
              onClick={assignCard}
            >
              Assign Card
            </ProgressButton>
          </Box>
        </FormProvider>
      </Box>
      {users.length > 0 && (
        <Box sx={{ mt: 4 }}>
          <ResponsiveTable
            tableHeadColumns={[
              { id: 'existing_user', label: 'Existing user' },
              { id: 'status', label: 'Status' },
              { id: 'actions', label: '', align: 'right' },
            ]}
          >
            {users.map((cardUser) => (
              <TableRow key={cardUser.id}>
                <TableCell>
                  {cardUser.paidolUser.user &&
                    `${cardUser.paidolUser.user?.first_name} ${cardUser.paidolUser.user.last_name} (${cardUser.paidolUser.user.email})`}
                  {!cardUser.paidolUser.user && `${cardUser.paidolUser.email}`}
                </TableCell>
                <TableCell>
                  {cardUser.paidolUser?.user && <Chip color="success" label="Registered" />}
                  {!cardUser.paidolUser?.user && <Chip color="grayLighter" label="Invited" />}
                </TableCell>
                <TableCell align="right">
                  <IconButton
                    sx={{ color: theme.palette.error.main }}
                    onClick={() => removeUser(cardUser.id)}
                    disabled={!!removePending}
                  >
                    {removePending === cardUser.id ? <CircularProgress size={24} /> : <Close />}
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </ResponsiveTable>
        </Box>
      )}
    </>
  );
}

export default AssignCardForm;
