/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cloud_logic_custom": [
        {
            "name": "dwollaWebhookEndpoint",
            "endpoint": "https://mnfvd8rc2m.execute-api.us-east-2.amazonaws.com/main",
            "region": "us-east-2"
        },
        {
            "name": "hnCollaborativeAuthorizationEndpoint",
            "endpoint": "https://tjau9w7ufc.execute-api.us-east-2.amazonaws.com/main",
            "region": "us-east-2"
        },
        {
            "name": "hnWebhookEndpoint",
            "endpoint": "https://oe27iwasab.execute-api.us-east-2.amazonaws.com/main",
            "region": "us-east-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://6hwsz2u5hvambhlyt5kzhdwtse.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-s2wnzlomnje43bqnea37cig2ay",
    "aws_cognito_identity_pool_id": "us-east-2:9ddd8664-bbbd-4bfe-b1c9-ca0ce8f0d3e8",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_xqCig3uwS",
    "aws_user_pools_web_client_id": "3oh07bvhs34uaok83jc505ue1j",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "FAMILY_NAME",
        "GIVEN_NAME",
        "PREFERRED_USERNAME"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "user141142-main",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
